//import logo from './logo.svg'
import './App.css'
import Signin from './Components/Signin'

function App() {
  return (
    <Signin />
  );
}

export default App;
