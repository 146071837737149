import { useState, useEffect } from 'react';
//import { useAxiosPost } from '../Hooks/HttpRequests';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Axios from 'axios';

var params = new URLSearchParams();

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://cfa.siam.luxe/">
                Siam Adventique Co., Ltd.
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [fetchedData, setFetchedData] = useState('');
    const classes = useStyles();

    const onEmailChange = (event) => {
        // setEmail(event.target.value);
        params.append('submit', 'signin');
        params.append('username', event.target.value);
    };

    const onPasswordChange = (event) => {
        // setPassword(event.target.value);
        params.append('password', event.target.value);
    };

    const url = 'https://cfa.siam.luxe/signin/api/';

    async function fetchData() {
        const { data } = await Axios.post(
            url,
            params
        )
        setFetchedData(data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleSubmit = e => {
        //e.preventDefault()
        fetchData()
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src="https://cfa.siam.luxe/images/logo.png" alt="logo" />
                <form className={classes.form} onSubmit={handleSubmit} noValidate action='https://cfa.siam.luxe/' method="post">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={onEmailChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={onPasswordChange}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                        checked={true}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In to CFA
          </Button>
                    
                </form>

                {/* <h1>{email} </h1>
                <h1>{password} </h1> */}
                <h1>{fetchedData}</h1>

            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}